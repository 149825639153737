<template>
	<div :class="'page page-' + slug + ' background-'">
		<RenderBlocks :blocks="data.blocks" />
	</div>
</template>

<script>
import { GET_BLOCKS } from "@/queries/Blocks.js";
import RenderBlocks from "@/components/blocks/RenderBlocks.vue";

export default {
	metaInfo() {
		if (this.seo) {
			return {
				title: this.seo.title,
				meta: [
					{
						name: "description",
						content: this.seo.metaDesc,
					},
					{
						name: "keywords",
						content: this.seo.metaKeywords ? this.seo.metaKeywords : this.defaultMetaKeywords,
					},
					{
						name: "author",
						content: this.seo.opengraphSiteName,
					},
					{
						property: "og:title",
						content: this.seo.title,
					},
					{
						property: "og:type",
						content: this.seo.opengraphType,
					},
					{
						property: "og:url",
						content: this.seo.opengraphUrl,
					},
					{
						property: "og:description",
						content: this.seo.metaDesc,
					},
					{
						property: "og:image",
						content: this.seo.opengraphImage ? this.seo.opengraphImage.sourceUrl : this.defaultUrlImage,
					},
					{ name: "robots", content: "index,follow" },
				],
			};
		}
	},
	components: {
		RenderBlocks,
	},
	data() {
		return {
			seo: null,
			slug: "",
			data: [],
			isPageLoaded: false,
			blocks: null,
			selectedIndexBlock: 0,
		};
	},
	async mounted() {
		this.loadStart();
		if (!this.slug) {
			this.slug = "home";
		} else {
			this.slug = this.$route.params.slug;
		}
		let type = "page";
		let query = {
			query: GET_BLOCKS(type, this.slug, "slug"),
		};

		let data = await this.$apollo.query(query);

		this.seo = data.data.page.seo;

		data = data.data[type];

		this.data = {
			title: data.title,
			blocks: JSON.parse("[" + data.blocksJSON + "]")[0],
		};
		if (this.$route.path === "/contatti") {
			this.updateProperty({
				property: "contactsDialog",
				value: true,
			});
		}
		if (this.$route.path === "/preiscrizione") {
			this.updateProperty({
				property: "subscriptionDialog",
				value: true,
			});
		}
		this.loadDone();
	},
};
</script>

<style lang="scss" scoped>
.customListItem {
	padding: 0;
}
.page {
	min-height: calc(100vh - 60px);
}
.title {
	padding: $spacing-0;
}
</style>
