import gql from "graphql-tag";

function GET_BLOCKS(post_type, id, idType) {
	switch (idType) {
		case "slug":
			idType = "URI";
			break;

		case "id":
			idType = "DATABASE_ID";
			break;
	}

	return gql`
		query GET_BLOCKS {
			${post_type}(id: "${id}", idType: ${idType}) {
				id
				blocksJSON
				uri
				date
				title
				seo {
					metaDesc
					metaKeywords
					title
					opengraphTitle
					opengraphSiteName
					opengraphPublisher
					opengraphType
					opengraphUrl
					opengraphPublishedTime
					opengraphModifiedTime
					opengraphImage {
					  id
					  uri
					  sourceUrl
					}
				  }
				children {
					edges {
						node {
							id
							slug
							... on Page {
								title
								id
								blocksJSON
								menuOrder
							}
						}
					}
				}				
			}
		}
	`;
}

export { GET_BLOCKS };
